import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-image-section',
  templateUrl: './services-image-section.component.html',
  styleUrls: ['./services-image-section.component.css'],
})
export class ServicesImageSectionComponent implements OnInit {
  images = [
    {
      path: '../../../../assets/img/services/event_security2.jpg',
      phone: '../../../../assets/img/services/event_security2_phone.jpg',

      title: 'Event Staffing',
      overlayText:
        'Guest security screening, venue roamer security, stage barricade, and other front-of-house and back-of-house security.',
    },
    {
      path: '../../../../assets/img/services/ticket_taking.jpg',
      phone: '../../../../assets/img/services/ticket_taking_phone.jpg',

      title: 'Ticket Taking',
      overlayText:
        'Ensure guests have valid ticket for the event. Reconcile count at the end of shift.',
    },   
    {
      path: '../../../../assets/img/services/crowd_management.jpg',
      phone: '../../../../assets/img/services/crowd_management_phone.jpg',

      title: 'Ushering',
      overlayText:
        'Assist guests locating their seats/suites, handle ticket issues and resolve problems, and direct guests in the event of an evacuation.',
    },
    {
      path: '../../../../assets/img/services/parking.jpg',
      phone: '../../../../assets/img/services/parking_phone.jpg',

      title: 'Parking Services',
      overlayText:
        'Inbound/outbound traffic control, flaggers/parkers, parking cashiers, and other parking related services.',
    },
    {
      path: '../../../../assets/img/services/usher.jpg',
      phone: '../../../../assets/img/services/usher_phone.jpg',

      title: 'Queue Line Management',
      overlayText:
        'Provide concierge/information desk, elevator/escalator staff, club/VIP suite attendants, and other guest services.',
    },
    {
      path: '../../../../assets/img/services/ambassadors.jpg',
      phone: '../../../../assets/img/services/ambassadors_phone.jpg',

      title: 'Guest Services',
      overlayText:
        'Provide concierge/information desk, elevator/escalator staff, club/VIP suite attendants, and other guest services.',
    }   
  ];
  targets: NodeListOf<Element>;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.intersectionFn();
  }, 2000);
  }

  intersectionFn() {
    this.targets = document.querySelectorAll('.imageSection');

    const lazyLoad = target => {
      const io = new IntersectionObserver((entries, observer) => {
      //  console.log('Entries', entries);
      //  console.log('Observer', observer);
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // fade in observed elements that are in view
            entry.target.classList.replace('fadeOut', 'fadeIn');
          } else {
            // fade out observed elements that are not in view
            entry.target.classList.replace('fadeIn', 'fadeOut');
          }
        });
      });
      io.observe(target);
    };
    this.targets.forEach(lazyLoad);
 
  }




}
