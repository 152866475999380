import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateService } from 'src/app/services/create.service';
import { ReadService } from 'src/app/services/read.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
type: 'about' | 'services' = 'about';
@ViewChild('btnRef') buttonRef;

  constructor(
    private readService: ReadService,
    private createService: CreateService
  ) { }

  ngOnInit(): void {
    this.createService.setPageTitleFn('About CSC Fundraising');
  }

changeNav(val){
  this.type = val;
}
  get isAbout(){
    return this.type === 'about';
  }
  get isServices(){
    return this.type === 'services';
  }
}
