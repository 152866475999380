import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReadService } from 'src/app/services/read.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UpdateService } from 'src/app/services/update.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css'],
})
export class EmailVerificationComponent implements OnInit {
  email: string;
  personEmail: any;
  isVerifying: boolean;
  isGood: boolean;

  constructor(
    private route: ActivatedRoute,
    private readService: ReadService,
    private updateService: UpdateService,
    private router: Router,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
    //  console.log(params);
      this.personEmail = params.email;
      sessionStorage.setItem(
        'NewsletterConfirmationEmail',
        JSON.stringify(this.personEmail)
      );
    //  console.log(this.personEmail);
      this.isVerifying = true;
    });
    this.checkExistsFn(this.personEmail);
  }
  async checkExistsFn(personEmail) {
    const checkEmail = await this.readService.returnPromiseWhereFn(
      'cscNewsletterSignup',
      'personEmail',
      personEmail
    );

   // console.log('checkEmail', checkEmail);
    if (!checkEmail?.length) {
      const snackClass = ['snackError'];
      const message = `${this.personEmail} not found`;
      this.snackbarService.openSnackBar(message, snackClass);
    } else {

   //   console.log('check Email length', checkEmail?.length)
      const e = {
        emailVerified: true,
      };
      this.isGood = true;
      this.updateService.
      updateRecordFn('cscNewsletterSignup', this.personEmail, e);
      this.isVerifying = false;
      setTimeout(() => {
        this.router.navigateByUrl('/newsfeed');
      }, 3000);
    }
  }
}
