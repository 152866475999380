import { Injectable } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
@Injectable({
  providedIn: 'root',
})
export class DateAndTimeService {
  constructor() {}


  convertDate(firebaseObject: any) {
    if (!firebaseObject) {
      return null;
    }

    for (const [key, value] of Object.entries(firebaseObject)) {
      // covert items inside array
      if (value && Array.isArray(value)) {
        firebaseObject[key] = value.map((item) => this.convertDate(item));
      }

      // convert inner objects
      if (value && typeof value === 'object') {
        firebaseObject[key] = this.convertDate(value);
      }

      // convert simple properties
      if (value && value.hasOwnProperty('seconds')) {
        firebaseObject[key] = (value as Timestamp).toDate();
      }
      // if (value && value.hasOwnProperty('seconds')) {
      //   firebaseObject[key] = (value as firebase.firestore.Timestamp).toDate();
      // }
    }
    return firebaseObject;
  }

  createdAtOffsetTimezoneDateFn() {
    const offset = new Date().getTimezoneOffset();
    //   console.log('OFFSET', offset);
    const created = new Date();
    const adjustedDateTimezone = created.setMinutes(
      created.getMinutes() + offset
    );
    //  console.log('Adjusted Date Timezone', adjustedDateTimezone);
    const createdDate = new Date(adjustedDateTimezone);
    //  console.log('CREATED DATE', createdDate);
    return createdDate;
  }
}
