<!-- #region NAME -->

<!-- #endregion NAME -->

<!-- #region NAME -->
<header>
  <video class="videoBg" autoplay="true" muted="muted" loop>
    <source
      src="../../assets/video/Contemporary Services Corporation.mp4"
      type="video/mp4"
    />
  </video>
  <div class="headerCopy">
    <div class="headerTitle">
      <h1>
        The <span class="cscGoldText">Leader</span> in Crowd Management.
        <sup>&#174;</sup>
      </h1>
      <h3>
        Let's be part of this
        <span class="cscGoldText">experience</span> together.
      </h3>
    </div>
    <div class="safetyActSection">
      <div class="safetyActCertifiedLogo">
        <img src="../../assets/img/safety_act_certification.png" alt="" />
      </div>
      <div class="safetyActCopy">
        <p>
          Contemporary Services Corporation (CSC) is recognized worldwide as the
          pioneer, expert and only employee owned company in the crowd
          management field. CSC developed the concept of peer group security
          techniques, and through over five decades of continual refinement and
          evolution, CSC has successfully remained the leader in the crowd
          management and event security industry.
        </p>
      </div>
    </div>
  </div>
  <div class="headerVideo">
    <video class="videoFg" controls="controls" autoplay="true" muted="muted" loop>
      <source
        src="../../assets/video/Contemporary Services Corporation.mp4"
        type="video/mp4"
      />
    </video>
  </div>
</header>
<!-- #endregion NAME -->
