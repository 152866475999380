<div class="footerSection">
  <div class="footerContentWrapper">
    <div class="cscLogoSection">
      <div class="cscImage">
        <img loading="lazy" src="../../../assets/img/logos/US_Crowd_Management_LLC_Logo_Full_Color_396w_222h.jpg" alt="" />
      </div>
    </div>
    <ng-container *ngIf="branchData | async as bd">
      <ng-container *ngFor="let x of bd">
        <div class="addressSection">
      <div class="contactinfoSection">
        <p><a href="mailto:{{x.branchEmailAddress}}">{{x.branchEmailAddress}}</a></p>
        <p>{{x.branchPhoneNumber1 | phone: 'US'}}</p>
        <p>{{x.branchAddress1}}</p>
        <p>{{x.branchAddress2}}</p>
        <p>{{x.branchAddressCity}}</p>
        <p>{{x.branchStateAbbr | uppercase}} {{x.branchAddressZip}}</p>
      </div>
    </div>
      </ng-container>
  

    </ng-container>
  
    <div class="linksSection">
      <mat-list-item [routerLink]="['/home']"><p>Home</p></mat-list-item>
      <!-- <mat-list-item [routerLink]="['/team']"><p>Team</p></mat-list-item> -->
      <!-- <mat-list-item (click)="anchorFn('ourServices')" ><p>Our Services</p></mat-list-item> -->
      <mat-list-item><a mat-button aria-label="Be part of our team, apply today!" href="https://hpuse.schedulingsite.com/verifyemail/1" target="_blank" rel="noopener noreferrer" class="registerBtn">
      <p>Apply</p>  </a></mat-list-item>
      <mat-list-item [routerLink]="['/privacy-policy']"><p>Privacy</p></mat-list-item>
      <!-- <mat-list-item><p></p>Get Started</mat-list-item> -->
    </div>

    <!-- <a mat-button aria-label="Be part of our team, apply today!" href="https://hpuse.schedulingsite.com/verifyemail/1" target="_blank" rel="noopener noreferrer" class="registerBtn">
      Apply</a> -->

    <!-- <div class="newsletterSection">
      <div *ngIf="!isSubmitted && !successfulRegistration">
        <button
          mat-raised-button
          class="cscGoldBtn"
          (click)="newsletterSignUp()"
        >
          Newsletter Sign Up
        </button>
      </div>
      <ng-container *ngIf="isSubmitted">
        <div>
          <p class="registering">Registering</p>
          <app-loading-spinner></app-loading-spinner>
        </div>
      </ng-container>

      <ng-container *ngIf="successfulRegistration">
        <div class="successMsg">
          {{ successMessage }}
        </div>
      </ng-container>
    </div> -->
 <ng-container *ngIf="branchData | async as sm">
  <ng-container *ngFor="let x of sm">
 <div class="socialMediaSection">
      <p>Follow Us</p>
      <div class="socialIcons">
        <a aria-label="facebook" href="{{x.branchFacebookLink}}" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook"> </i>
        </a>

   <!--Instagram-->
   <a aria-label="instagram" href="{{x.branchInstagramLink}}" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram-square"> </i></a>
   <!--LinkedIn-->
   <!-- <a aria-label="linkedin" href="https://www.linkedin.com/company" target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin"> </i></a> -->
      </div>
    </div>
    
  </ng-container>
      
    </ng-container>
   
  </div>
</div>
