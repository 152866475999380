import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormService } from 'src/app/services/form.service';
import { UpdateService } from 'src/app/services/update.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: [
    './dynamic-form.component.css',
    '../../site/work-to-win-giveaways/super-bowl-prizes/super-bowl-prize-application/super-bowl-prize-application.component.css',
    '../../admin/legal-admin/legal-create-form/legal-create-form.component.css'

  ]
})
export class DynamicFormComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('f') formData: any;
  // tslint:disable-next-line:no-input-rename
  @Input('p') patchData?: any;
  // tslint:disable-next-line:no-input-rename
  @Input('class') formCSSClass: any;
  @ViewChildren('selectOptions', { read: ElementRef }) configOptionsList: QueryList<ElementRef>;
  @ViewChildren('optionLoop') optionLoopList: QueryList<any>;
  @ViewChildren('matchArryName', { read: ElementRef }) matchArryName: QueryList<ElementRef>;
  @Output() submittedFormData = new EventEmitter();
  form: FormGroup;
  docId: any;
  favoriteSeason: string;
  filteredOptions: Observable<string[]>;
  filteredArray$: Observable<any>;
  controlName: any;
  controlOptions: any;
  constructor(
    public formService: FormService,
    private updateService: UpdateService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({});
    const formGroup = {};
    this.formData.forEach(formControl => {
      formGroup[formControl.controlName] = new FormControl('');
    });
    this.form = new FormGroup(formGroup);
   //  console.log('Form', this.formData);
  //  console.log('FormCSSClass', this.formCSSClass);


    this.form.valueChanges.subscribe((data) => {
   //   console.log(data);
      this.formService.logValidationErrors(this.form);
    });
    if (this.patchData?.length) {
    for (const x of this.patchData){
      this.docId = x.docId;
    }
   // console.log('Patch', this.patchData);
      // const patchArray = [];
      // patchArray.push(this.patchData);
    this.updateService.customPatchValue(this.patchData, this.form);
    }
  }

  fetchCol(controlName, ops){
   // console.log('OPS', ops);
    for (const x of controlName){
      this.controlName = x;
    }
    for (const x of ops){
       // console.log('OBJ X', x );
        this.controlOptions = x;
        // this.arrayToFilter = e;
       // console.log('OPTIONS', this.controlOptions);
    }
  //  console.log('EL', this.controlName);
    this.filteredArray$ = this.form.get(this.controlName).valueChanges.pipe(
           startWith(''),
           map(v => this.filterObject(v))
         );
   // console.log('FILTERED RES', this.filteredArray$);
  }


  filterObject(optionName){
  //  console.log('FILTERED RES', optionName );
  //  console.log('FILTERED OPS', this.controlOptions.filter(a => a.optionName));
    return this.controlOptions.filter(a =>
    a.optionName.toLowerCase().indexOf(optionName.toLowerCase()) > -1);
    }


    displayFn(book?): string | undefined {
     // console.log('BOOK RES', book);
      return book ? book.optionName : undefined;
    }

  onClicked() {
    const d = this.form.value;
    if (this.docId){
      d.docId = this.docId;
    }
    const data = {
      ...d
    };
  //  console.log('EMITTED DATA', data);
    try {
      this.submittedFormData.emit(data);
      this.form.reset();
    } catch (e) {
   //   console.log('ERROR', e);

    }

  }
}
