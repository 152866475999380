import { Injectable } from '@angular/core';
import { deleteDoc, doc, Firestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../shared/delete-dialog/delete-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DeleteService {

  constructor(
    private afs: Firestore,
    public dialog: MatDialog
    ) { }

    deleteRecordFn(documentPath: any, docId: string){
      // console.log('DEL COL', collection);
      // console.log('DEL DOC ID', docId);
       const docRef = doc(this.afs, documentPath, docId);
       deleteDoc(docRef);
    }

    deleteFn(
      recordPath: string,
      docId: string,
      itemName?: string
      ) {
        console.log('recordPath', recordPath);
        console.log('docId', docId);
        console.log('itemName', itemName);
     const dialogRef = this.dialog.open(DeleteDialogComponent, {
       panelClass: 'dialogConfirm',
       data: {
         itemName,
         firestorePath: recordPath,
         docId
       }
     });
     dialogRef.afterClosed().subscribe(res => {
       if (res) {
         console.log(res.data);
         try {
          const docRef = doc(this.afs, recordPath, docId);
          deleteDoc(docRef);

         } catch (error) {
           console.log('DIALOG RES ERROR', error);
         }
       } else if (res && res.msg === 400) {
         console.log('DIALOG RES ERROR');
       }
     });
   }
}
