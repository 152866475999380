import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ContactFormComponent } from './site/contact-form/contact-form.component';
import { AdminModule } from './admin/admin.module';
import { MessageInboxComponent } from './shared/message-inbox/message-inbox.component';
import { MessageOutboxComponent } from './shared/message-outbox/message-outbox.component';
import { AboutComponent } from './site/about/about.component';

import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminGuard } from './auth/admin.guard';
import { HeaderComponent } from './homepage/header/header.component';
import { GoldStandardComponent } from './homepage/gold-standard/gold-standard.component';
import { OurExperienceComponent } from './homepage/our-experience/our-experience.component';
import { LocationsComponent } from './homepage/locations/locations.component';
import { DhsCertifiedComponent } from './homepage/dhs-certified/dhs-certified.component';
import { EmailVerificationComponent } from './site/email-verification/email-verification.component';
import { EmailUnsubscribeComponent } from './site/email-unsubscribe/email-unsubscribe.component';
import { sectionMetadata } from './site/sections-metadata';

import { RedirectGuard } from './auth/redirect.guard';



const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'home',
    data: sectionMetadata.homePage,
    loadChildren: () =>
      import('./site/use-us/use-us.module').then((m) => m.UseUsModule),
  },
  {path: 'login',
  loadChildren: () => import ('./auth/auth.module').then(m => m.AuthModule)},
  {
    path: 'admin',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
 { path: 'about', component: AboutComponent },
  {
    path: 'SuperBowlLVI',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://hportal.schedulingsite.com/verifyemail/5',
    },
  },
  { path: 'email-verification', component: EmailVerificationComponent },
  { path: 'email-unsubscribe', component: EmailUnsubscribeComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'inbox', component: MessageInboxComponent },
  { path: 'outbox', component: MessageOutboxComponent },
  { path: 'login', component: LoginComponent },
  { path: 'contact', component: ContactFormComponent }, 
  {
    path: 'join-our-team',
    data: sectionMetadata.joinUsPage,
    loadChildren: () =>
    import('./site/join-us/join-us.module').then((m) => m.JoinUsModule),
  },
  {
    path: 'privacy-policy',
    data: sectionMetadata.privacyPolicyPage,
    loadChildren: () =>
      import('./site/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'services',
    data: sectionMetadata.servicesPage,
    loadChildren: () =>
      import(
        './site/use-us/use-us-services-dashboard/use-us-services-dashboard.module'
      ).then((m) => m.UseUsServicesDashboardModule),
  },

  
  { path: '**', pathMatch: 'full', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [

  ],
})
export class AppRoutingModule {
  static components = [
    HomepageComponent,
    HeaderComponent,
    LoginComponent,
    SignupComponent,
    GoldStandardComponent,
    OurExperienceComponent,
    LocationsComponent,
    DhsCertifiedComponent,
    EmailVerificationComponent,
    EmailUnsubscribeComponent
  ];
}
