<div class="allServicesWrapper">
  <div class="servicesImageSection">
    <ng-container *ngFor="let x of images">
      <div class="imageSection fadeOut">
        <div class="imageTitle">
          <p>{{ x.title }}</p>
        </div>
        <div class="imageWrapper">
          <picture>
            <source loading="lazy"
              media="(min-width: 300px) and (max-width:767px)"
              srcset="{{x.path}}"
            />
            <source loading="lazy"
              media="(min-width: 768px) and (max-width:5000px)"
              srcset="{{x.path}}"
            />
            <img loading="lazy" src="{{ x.phone }}" alt="{{ x.title }}" />
            <div class="imageOverlay">
              <p class="imageOverlayTitle">{{ x.title }}</p>
              <p class="imageDescription">
                {{ x.overlayText }}
              </p>
            </div>
          </picture>
          <!-- <img src="{{x.phone}}" alt="{{x.title}}"
         srcset="{{x.phone}} 300w, {{x.path}} 600w"
         sizes="(min-width: 300px) 150px, (min-width: 768px) 300px"
         > -->
        </div>
      </div>
    </ng-container>
  </div>

  <!-- <div class="linkBtn">
  <button mat-raised-button [routerLink]="['/services']">View Services <span class="material-icons">
    launch
    </span></button>
</div> -->
</div>
