import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { map, take, switchMap } from 'rxjs/operators';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarVerticalPosition,
  MatSnackBarHorizontalPosition,
} from '@angular/material/snack-bar';
import { ReadService } from '../services/read.service';
import { SnackbarService } from '../services/snackbar.service';
@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  user$: Observable<any>;
  adminArray: any[];
  personData = '';
  loggedInUid = '';
  isAuth;
  adminCheck;
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  userProfile$: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private auth: Auth,
    private readService: ReadService,
    private snackbarService: SnackbarService
  ) {}


  canActivate() {
    this.user$ = new Observable((observer: any) =>
    onAuthStateChanged(this.auth, observer)
  );
    return this.user$.pipe(
      take(1),
      switchMap(async (authState) => {
        this.adminArray = [];
        const u = await this.readService.returnPromiseOrderByAndSingleFieldFilterFn(
          'uscmAdmin/legal/authUsers',
          'personEmail',
          'asc',
          'personEmail'
        );
        //  console.log('U', u);

        u.forEach((e) => {
          this.adminArray.push(e);
        });
          console.log('ADMIN', u);
          console.log('AuthState', authState);
        if (authState) {
          // check are user is logged in
          //    console.log('AuthState', authState.email);

          //    console.log('ARRAY', this.adminArray);
          const authEmail = authState.email;
          if (this.adminArray.includes(authEmail)) {
            //    console.log('Auth Email', authEmail)
            return true;
          } else {
            //    console.log('NOT PERMITTED')

            const snackClass = ['snackError'];
            const message = 'You are not permitted to access this page.';
            this.snackbarService.openSnackBar(message, snackClass);
            this.router.navigate(['/services']);

            return false;
          }
        } else {
          this.router.navigate(['/']);
          return false;
        }
      })
    );
  }
}
