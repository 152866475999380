// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyDbsNf169DzqiIv88JFQJG9cxRxax-Sfv0",
//     authDomain: "us-crowd-management.firebaseapp.com",
//     projectId: "us-crowd-management",
//     storageBucket: "us-crowd-management.appspot.com",
//     messagingSenderId: "17934185848",
//     appId: "1:17934185848:web:5c61692601dd5ff955848d",
//     measurementId: "G-YF2HK2423B"
//   }
// };

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDiDs8ZEul9GDHuv5bckN274090Fh4s4x8",
  authDomain: "us-crowd-management-8c3fb.firebaseapp.com",
  projectId: "us-crowd-management-8c3fb",
  storageBucket: "us-crowd-management-8c3fb.appspot.com",
  messagingSenderId: "349885229222",
  appId: "1:349885229222:web:32d3ca0800b4906ec02c67",
  measurementId: "G-SXLLJ008N5"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
