import { Injectable } from '@angular/core';
import { map, filter, switchMap, take, first, tap } from 'rxjs/operators';


import { DateAndTimeService } from './date-and-time.service';
import { Firestore,orderBy, collection, collectionData, query, getDocs, where
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ReadService {
  constructor(
    private afs: Firestore,
    private dateTime: DateAndTimeService
  ) {}

  // getBranches() {
  //   return this.afs
  //     .collection('cscBranchDirectory', (ref) =>
  //       ref.orderBy('branchName', 'asc')
  //     )
  //     .snapshotChanges()
  //     .pipe(
  //       map((docArray: any) => {
  //         return docArray.map((doc: any) => {
  //           const data = doc.payload.doc.data();
  //           console.log(data);
  //           return data;
  //         });
  //       })
  //     );
  // }

  // async branchNamesFn(
  //   collection: string,
  //   field: string | firebase.firestore.FieldPath,
  //   direction: any
  // ) {
  //   const d = await firebase
  //     .firestore()
  //     .collection(collection)
  //     .orderBy(field, direction)
  //     .get();
  //   const data = [];
  //   d.forEach((x) => {
  //     if (x) {
  //       const obj = {
  //         optionName: x.data().branchName.toUpperCase(),
  //         value: x.data().branchName.toUpperCase(),
  //       };
  //       data.push(obj);
  //     }
  //   });
  //   return data;
  // }

  async returnSelectOptionsPromiseOrderBy(
    collectionPath,
    fieldName,
    fieldValue,
    direction
  ) {
    // console.log(collectionPath);
    // console.log(fieldName);
    // console.log(fieldValue);
    // console.log(direction);
    const colRef = await getDocs(query(collection(this.afs, collectionPath), orderBy(fieldName, direction)));

    const data = [];
    colRef.forEach(x => {
      if (x) {
         const dataList = x.data();
         const obj = {
           optionName: ` ${x.data()[fieldName]}`.toUpperCase(),
           value: `${x.data()[fieldValue]}`,
         }
        // const obj = this.dateTime.convertTimestampFn(dataList);
      //   console.log(dataList);
        data.push(obj);
      }
    });
    return data;
  }

  // async returnPromiseFieldOrderBy(collection, field, direction) {
  //   const d = await firebase
  //     .firestore()
  //     .collection(collection)
  //     .orderBy(field, direction)
  //     .get();
  //   const data = [];
  //   d.forEach((x) => {
  //     if (x) {
  //       const dataList = x.data().personEmail;
  //       const obj = this.dateTime.convertDate(dataList);
  //       data.push(obj);
  //     }
  //   });
  //   return data;
  // }
  async returnPromiseOrderByAndSingleFieldFilterFn(collectionPath: any, orderProperty: string, orderValue: any, whereProperty: string) {
    const colRef = await getDocs(query(collection(this.afs, collectionPath), orderBy(orderProperty, orderValue)));
    const resultsArray: any[] = []
     colRef.forEach(async (doc)=> {
      const data = doc.data();
      const id = doc.id;
      const singleProperty = data[whereProperty];
        const obj = await this.dateTime.convertDate(singleProperty);
      resultsArray.push({id,  ...obj})
  })
    return resultsArray;
  }

  // async fetchFirestorePromiseOrderBy(collection, field, direction) {
  //   const d = await firebase
  //     .firestore()
  //     .collection(collection)
  //     .orderBy(field, direction)
  //     .get();
  //   const list = [];
  //   d.forEach((x) => {
  //     if (x) {
  //       const dataList = x.data();
  //       list.push(dataList);
  //     }
  //   });
  //   return list;
  // }
  async returnPromiseOrderByFn(collectionPath: any, orderProperty: string, orderValue: any) {
    const colRef = await getDocs(query(collection(this.afs, collectionPath), orderBy(orderProperty, orderValue)));
    const resultsArray: any[] = []
     colRef.forEach(async (doc)=> {
      const data = doc.data();
      const id = doc.id;
        const obj = await this.dateTime.convertDate(data);
      resultsArray.push({id,  ...obj})
  })
    return resultsArray;
  }


  // async fetchWherePromise(collection, whereField, whereValue) {
  //   const d = await firebase
  //     .firestore()
  //     .collection(collection)
  //     .where(whereField, '==', whereValue)
  //     .get();
  //   const list = [];
  //   d.forEach((x) => {
  //     if (x) {
  //       const dataList = x.data();
  //       list.push(dataList);
  //     }
  //   });
  //   return list;
  // }

  async returnPromiseWhereFn(collectionPath: any, whereProperty: string, whereValue: any ) {
    const resultsArray: any[] = []
    const colRef = await getDocs(query(collection(this.afs, collectionPath), where(whereProperty, '==', whereValue)));
    console.log('colRef', colRef);
    colRef.docs.map( f => {
   //   console.log('F', f.data());
      const data = f.data();
   //   console.log('DATA', data);
      const id = f.id;
      const obj =  this.dateTime.convertDate(data);
      resultsArray.push({id,  ...obj})
    });
   //  console.log('ResultsArray ', resultsArray);
     return resultsArray;
  }


  // returnRecordsOrderByFn(collection, field, direction) {
  //   // console.log();
  //   return this.afs
  //     .collection(collection, (ref) => ref.orderBy(field, direction))
  //     .snapshotChanges()
  //     .pipe(
  //       map((docArray: any) => {
  //         return docArray.map((doc: any) => {
  //           const data = doc.payload.doc.data();
  //           const obj = this.dateTime.convertDate(data);
  //           return obj;
  //         });
  //       })
  //     );
  // }

  returnObservableOrderByFn(collectionPath: any, orderProperty: string, orderValue: any ): Observable<any[]> {
    //  console.log('collection PATH', collectionPath);
      const colRef = collection(this.afs, collectionPath);
    //  console.log('COLREF', colRef);
      const q = query(colRef, orderBy(orderProperty, orderValue ));
   //   console.log('QQQ', q);
      const res = collectionData(q) as Observable<any[]>;
      return res.pipe(
        map((data: any) => {
          const obj = this.dateTime.convertDate(data);
        //  console.log('CONVERTED DATA', obj)
          return obj;
        })
      )
    }

  // returnRecordsWhereFn(collection, field, value) {
  //   return this.afs
  //     .collection(collection, (ref) => ref.where(field, '==', value))
  //     .snapshotChanges()
  //     .pipe(
  //       map((docArray: any) => {
  //         return docArray.map((doc: any) => {
  //           const data = doc.payload.doc.data();
  //           if (data.createdDate) {
  //             data.createdDate = data.createdDate.toDate();
  //           }
  //           //  console.log(data);
  //           return data;
  //         });
  //       })
  //     );
  // }

  returnObservableWhereFn(collectionPath: any, whereProperty: string, whereValue: any ): Observable<any[]> {
    // console.log('collection', collectionPath);
 const colRef = collection(this.afs, collectionPath);
    // console.log('COLREF', colRef);
 const q = query(colRef, where(whereProperty, '==', whereValue ));
 const res = collectionData(q) as Observable<any[]>;
//    console.log('CD', res);
return res.pipe(
   map((data: any) => {
     const obj = this.dateTime.convertDate(data);
   //  console.log('CONVERTED DATA', obj)
     return obj;
   })
 )
}


  // returnOrderByAndFilterFn(
  //   collection: string,
  //   property: string,
  //   direction: any,
  //   filterProperty: string,
  //   filterValue: string
  // ) {
  //   const rec = this.afs
  //     .collection(collection, (ref) => ref.orderBy(property, direction))
  //     .snapshotChanges()
  //     .pipe(
  //       map((docArray: any) => {
  //         return docArray
  //           .map((doc: any) => {
  //             const data = doc.payload.doc.data();
  //             return data;
  //           })
  //           .filter((data) => data[filterProperty] === filterValue);
  //       })
  //     );
  //   return rec;
  // }

  returnObservableOrderByAndFilterFn(collectionPath: any, orderProperty: string, orderValue: any, whereProperty: any, whereValue: any ): Observable<any[]> {
    // console.log('collection', collectionPath);
    const colRef = collection(this.afs, collectionPath);
   // console.log('COLREF', colRef);
    const q = query(colRef, orderBy(orderProperty, orderValue ));
    const res = collectionData(q) as Observable<any[]>;
   //  console.log('CD', res);
 return res.pipe(
    map(x => x.filter(item => item[whereProperty] === whereValue)),
    map((data: any) => {
      const obj = this.dateTime.convertDate(data);
      return obj;
    })
  )
}

  // returnWhereAndFilterFn(
  //   collection: string,
  //   property: string,
  //   value: string,
  //   filterProperty: string,
  //   filterValue: string
  // ) {
  //   const rec = this.afs
  //     .collection(collection, (ref) => ref.where(property, '==', value))
  //     .snapshotChanges()
  //     .pipe(
  //       map((docArray: any) => {
  //         return docArray
  //           .map((doc: any) => {
  //             const data = doc.payload.doc.data();
  //             return data;
  //           })
  //           .filter((data) => data[filterProperty] === filterValue);
  //       })
  //     );
  //   return rec;
  // }
}
